import React, { useRef, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import VideoJS from "./VideoJS";
import "../styles/index.css";
import "../styles/customize-progress-bar.css";
import "../styles/video-js.css";

const VideoTest = React.forwardRef((props, ref) => {
	let nodeRef = useRef(JSON.parse(JSON.stringify(props.node)));
	const [data, setData] = useState("<span class='loader'></span>");

	let file = nodeRef.current.file;
	let href = file.startsWith("http") ? file : "/testFiles/" + file;
	let extension = file.substring(file.lastIndexOf(".") + 1);
	let type = extension === "m3u8" ? "application/x-mpegURL" : "video/" + extension;

	const videoJsOptions = {
		autoplay: true,
		playbackRates: [0.75, 1, 1.25, 1.5, 2.0],
		fluid: true,
		responsive: true,
		controls: true,
		sources: [
			{
				src: href,
				type: type
			}
		]
	};
	let video = useRef();
	let playerRef = useRef();
	let timerRef = useRef();
	let lastStatus = useRef("");

	const getVideoStatus = () => {
		const player = playerRef.current;
		let duration = player.duration();
		let elapsed = player.currentTime();
		if (duration > 0) {
			let percent = Math.floor((elapsed * 1000) / duration) / 10;
			let status = "percent played " + percent;
			if (status !== lastStatus.current) {
				console.log(status);
				lastStatus.current = status;
			}
		}
	};

	const handlePlayerReady = (player) => {
		playerRef.current = player;
		if (timerRef.current) clearInterval(timerRef.current);
		timerRef.current = setInterval(getVideoStatus, 5000);

		// Audio introspection
		let aTracks = player.audioTracks();
		console.log(aTracks);

		// You can handle player events here, for example:
		player.on("waiting", () => {
			console.log("player is waiting");
		});

		player.on("loadedmetadata", () => {
			console.log("loadedmetadata");
		});

		player.on("play", () => {
			console.log("play has started");
		});

		player.on("stalled", () => {
			console.log("stalled");
		});

		player.on("dispose", () => {
			console.log("player will dispose");
		});
	};

	useEffect(() => {
		// runs after render to start fetching data
		video.current = document.getElementById("hlmdVideo");
		try {
			let file = nodeRef.current.file;
			let href = file.startsWith("http") ? file : "/testFiles/" + file;
			console.log("href " + href);
			if (href) setData(href); // triggers a re-render with new data
			// video.current.addEventListener("canplay", canPlay);
			// video.current.addEventListener("canplaythrough", canPlayThrough);
			// video.current.src = href;
			// video.current.load();

			//recent.update(nodeRef.current);
			// });
		} catch (error) {
			console.error(error.message);
			setData("<p style='margin:18px; color:DarkRed'>" + error.message + "</p>");
		}
		// if (!nodeRef.current) {
		// 	// bookkeeping
		// 	// recent.update(nodeRef.current); // initial history update even though
		// }
		return () => {
			if (timerRef.current) {
				clearInterval(timerRef.current);
				timerRef.current = null;
			}
			// 	video.current.removeEventListener("canplay", canPlay, { passive: true });
			// 	video.current.removeEventListener("canplaythrough", canPlayThrough, {
			// 		passive: true
			// 	});
		};
	}, [props.node]);
	console.log(data);

	return (
		<div {...props} ref={ref}>
			<Box sx={{ py: 4 }}>
				{" "}
				{/* smaller padding since floating div above consumes space */}
				<br></br>
				{/* <div className='audio-player'>
                    <div className='inner'>
                        <AudioDisplayTrack {...{ currentTrack, audioRef, setDuration, progressBarRef }} />
                        <Controls {...{ audioRef, progressBarRef, duration, setTimeProgress }} />
                        <ProgressBar {...{ progressBarRef, audioRef, timeProgress, duration }} />
                    </div>
                </div> */}
			</Box>

			<Box sx={{ py: 4 }}>
				{" "}
				<div>
					<VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
				</div>
			</Box>
		</div>
	);
});

export default React.memo(VideoTest);
