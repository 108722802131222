import React, { Component } from "react";
import logo from "../logo.svg";
import { base64ToUint8 } from "../utilities/base64";
import { dateToString } from "../utilities/dateFormatter";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import transEN from "../translations/en.json";
import transCN from "../translations/cn.json";

function SplashMessage() {
	return (
		<div>
			<img src={logo} className="App-logo" alt="logo" />
		</div>
	);
}
// login types (string)
//	1	first time login, proceed to login page
//	0	subsequent login, no need to check password
//	2	subsequent login, verify password
//	3	auto login (no password set; process the tid)
// optional 'query' for start page (all login types)
// tid	tinyID

export default function withSplashScreen(WrappedComponent) {
	return class extends Component {
		constructor(props) {
			super(props);
			this.state = {
				loading: true,
				loginType: 1,
				tid: ""
			};
		}
		// key functions:
		// get a login type (i.e. how often password is confirmed) to give to App
		// start to set up cryptography using date-time strings as secrets
		// code is intentionally disorganized
		getLoginType() {
			let firstTime = false;
			let storage = window["localStorage"];
			let date = "";
			let loginType = 1;
			let tid = "";
			let query = window.location.search; // TODO: switch to parameter parsing?
			if (query.startsWith("?tid=")) tid = query.substring(5);
			if (storage) {
				date = storage.getItem("a");
				if (date && date.indexOf("-") !== 4) date = null; // make sure it seems valid (could be more rigorous)
				const options = storage.getItem("c"); // encryption salt merged with login type
				const now = new Date();
				if (!date || !options) {
					date = dateToString(now);
					storage.setItem("a", date); // record first login date-time, used to generate persistent key
					firstTime = true;
					console.log("first time " + tid);
				}
				if (!window.document.expTime) window.document.expTime = {}; // use DOM for session storage (obfuscate)
				window.document.expTime.startT = date;
				if (!firstTime && options) {
					const bytes = base64ToUint8(options);
					const flags = bytes[15] & 3; // extract login type
					window.document.expTime.opt = options; // save for faster access
					if (flags === 1 || flags === 2) {
						// console.log("processing 1 or 2");
						let last = storage.getItem("d") || date; // last login
						last = last.replace(" ", "T"); // convert to standard date time string
						let elapsed = 1;
						elapsed = (now - Date.parse(last)) / 1000 / 60 / 60; // / 24 ; // days
						elapsed = Math.round(flags === 1 ? elapsed / 7 : elapsed / 30);
						// console.log("elapsed "+elapsed);
						if (elapsed > 0)
							loginType = 2; // time to log in again
						else loginType = 0;
					} else if (flags === 3) {
						loginType = 2; // always log in
					} else {
						loginType = 0; // never log in
					}
				} else {
					storage.removeItem("c"); // discard encryption seed, force flush of storage
				}
			}
			//console.log(window.document.expTime);
			if (firstTime && tid) loginType = 3;
			return [loginType, tid];
		}
		async componentDidMount() {
			i18next // init internationalization with logo showing
				.use(LanguageDetector)
				.use(initReactI18next)
				.init({
					debug: false,
					detection: {
						order: ["localStorage", "navigator"]
					},
					resources: {
						en: { translation: transEN },
						cn: { translation: transCN }
					},
					fallbackLng: "en",
					interpolation: {
						escapeValue: false // react already safe from xss => https://www.i18next.com/translation-function/interpolation#unescape
					}
				});
			try {
				//
				const [loginType, tid] = this.getLoginType();
				this.setState({ loading: false, loginType: loginType, tid: tid });
				// setTimeout(() => { // simulate slow load so as to see the logo longer
				//     this.setState({
				//         loading: false,
				//     });
				// }, 500)
			} catch (err) {
				console.log(err);
				this.setState({
					loading: false,
					loginType: 7 // error thrown
				});
			}
		}
		async componentWillUnmount() {
			this.setState({ loading: true, loginType: 1, tid: "" }); // undo DidMount, so that strict doesn't mess things up?
		}

		render() {
			// while checking user session, show "loading" message
			if (this.state.loading) return SplashMessage();

			// otherwise, show the desired route
			const expandedProps = { ...this.props, ...this.state };
			return <WrappedComponent {...expandedProps} />;
		}
	};
}
